<template>
  <div
    class="separator"
    :class="{ 'dark-mode': isDark, 'light-mode': !isDark}"
  >
    <div class="label">
      <h4 style=" font-size: 15px; font-weight: 600;">
        {{ label }}
      </h4>
    </div>
  </div>
</template>
<script>
import { getVuetify } from '@/@core/utils'

export default {
  name: 'Separator',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },
}
</script>
<style lang="scss" scoped>
  .separator{
    position: relative;
    border-top: 1px solid #cac9cd96;
    margin-top: 20px;
  }
  .separator .label{
    top: -19px;
    left: 20px;
    position: absolute;
    z-index: 100;
    padding: 8px;
  }
  .dark-mode{
    color: #FFF;
  }
  .dark-mode .label{
    background: #312d4b;
  }
  .light-mode{
    color: #736c7d;
    background: #000;
  }
  .light-mode .label{
    background: #FFF;
  }
</style>
